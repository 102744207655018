


import React, { useEffect, useRef, useState } from 'react';
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./AppRouter.js";
import {observer} from "mobx-react-lite";
import './App.css';
import NavBar from './pages/NavBar.js';
import $ from 'jquery'
import LenisScroll from './utils/Lenis.tsx';
import ScrollToTop from './ScrollRouter.js'
import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import Footer from './pages/Footer.js'

const App = observer(() => {

    const [screenLoading, setScreenLoading] = useState(false);

    useEffect(() => {
      setScreenLoading(true);
      setTimeout(() => {
        setScreenLoading(false);
      }, 4500);

      const timeone =  () => {
        setTimeout(() => {
            $('.svg_preloder1').removeClass('svg_preloder1_animate')
            $('.svg_preloder2').removeClass('svg_preloder2_animate')
            $('.svg_preloder3').removeClass('svg_preloder3_animate')
            timetoo()
            }, 1400)
      }
      const timetoo =  () => {
        setTimeout(() => {
            $('.svg_preloder1').addClass('svg_preloder1_animate')
            $('.svg_preloder2').addClass('svg_preloder2_animate')
            $('.svg_preloder3').addClass('svg_preloder3_animate')
            timeone()
            }, 1600)
      }

      timetoo()



    }, []);
    return (
        
        <> 
   



          <BrowserRouter >
<ScrollToTop/>

          <AppRouter /> 
          <Footer/>
      </BrowserRouter>

        
        </>

    );
    
});

export default App;