export const GLAV_ROUTE = '/'
export const OOMPANII_ROUTER = '/info'
export const USLUGI_ROUTER = '/uslugi'
export const EXPERTIZA_ROUTER = '/expertiza'
export const POJECTS_ROUTER = '/projects'
export const POJECTS_SHABLON_ROUTER = '/Project_shablon'
export const KONTACTS_ROUTE = '/kontacts'

export const VDNX_ROUTE = '/vdnx'




export const Ekat_ROUTE = '/Ekat'
export const Electrostal_ROUTE = '/Electrostal'
export const Kazan_Expr_ROUTE = '/Kazan_Expr'
export const Krasnodar_ROUTE = '/Krasnodar'
export const Kuchino_ROUTE = '/Kuchino'
export const Nevinnomisk_ROUTE = '/Nevinnomisk'
export const New_Moskow_ROUTE = '/New_Moskow'
export const Novosib_ROUTE = '/Novosib'
export const Podolsk_ROUTE = '/Podolsk'
export const Samara_ROUTE = '/Samara'
export const Sarpul_ROUTE = '/Sarpul'
export const SPB_ROUTE = '/SPB'
export const Tambov_ROUTE = '/Tambov'
export const Tula_ROUTE = '/Tula'
export const Vladimir_ROUTE = '/Vladimir'
export const Yurga_ROUTE = '/Yurga'

export const IZISK_ROUTE = '/nadzor'
export const NADZOR_ROUTE = '/inz_iziskaniya'
export const PROEKTIR_ROUTE = '/projectirovaniye'

export const POLITIKA_ROUTE = '/politika'
export const STR_ROUTE = '/complex'

export const Staty_ROUTE = '/content'


